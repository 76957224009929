<template>
    <div class="pg">
        <!-- 更多公司荣誉 -->
        <Top  :topIndex="6"  @changeL="changeL"></Top>
        <div style="min-height:10vh">
             <img :src="cn_enobj.site.index.home_logo"  class="zhanweiImg" alt="">
        </div>
        <div class="descBox-box2">
            <div  class="descBox-box2-btn" @click="comeback()">
                <img src="@/assets/img/返回.png" alt="">
                <span style="color: #01AE0D;font-size: 2vmin;">{{cn_enobj.site.components.bottom.back_bottom}}</span>
            </div>
        </div>
        <div class="pglist">
            <div class="labelsTop">
                <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.aboutUs.honors.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.aboutUs.honors.subtitle}}</div>
                    </div>
                </div>
            </div>
            <div class="item2 reveal-bottom">
                <div class="item2-box"  v-for="(i,index) in list" :key="index" @click="goDetaile(i)">
                    <div class="wenanLable">{{locale === 'zh'?i.title:i.en_title}}</div>
                    <div class="xiangqin"> {{locale == 'zh'?i.introduce:i.en_introduce}}</div>
                    <p style="color:#BCBCBC;font-size:1.6vmin">{{i.publish_time}}</p>
                </div>
               
            </div>
        </div>
        <div class="loadMore">
            <div class="loadMore-btn reveal-r" @click="loadMoreEv()">{{cn_enobj.site.components.bottom.more_bottom}}</div>
        </div>
        <Bottom></Bottom>
        <ReturnTop></ReturnTop>
    </div>
</template>

<script>
import Top from '@/components/top.vue'
import Bottom from '@/components/bottom.vue'
import ReturnTop from '@/components/returnTop.vue'
import scrollReveal from 'scrollreveal'
export default {  
    components:{Top,Bottom,ReturnTop},
    data () {
        return {
            scrollReveal: scrollReveal(),
            desc: "<p>I believe I can fly</p>",
            locale:'',
            list : [],
            page:1,
            isDisable:false,
            cn_enobj:{}
        }
    },
     //项目中执行，自定义类名。
    mounted() {
        this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
        this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9});
        // this.$scrollFun()
    },
    created(){
        this.getGloryData() // 获取公司荣誉列表
        this.changeL(this.$i18n.locale)
    },
    beforeDestroy(){
        this.page = 1
        this.list = []
    },
    methods: {
        comeback(){
            this.$router.go(-1)
        },
        
        changeL(str){
            this.locale = str
            this.cn_enobj = this.locale == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
        },
         // 获取公司荣誉
        getGloryData(){
            this.$axios({
                method: 'get',
                url: '/api/content/list/glory?page='+this.page,
            }).then(res=>{
                if(res.data.data.data.length == 0){
                    this.$message({
                        message: '没有更多数据了！',
                        type: 'warning'
                    });
                    this.isDisable = true
                    return
                }else{
                    this.isDisable = false
                    this.list = this.list.concat(res.data.data.data)
                }
                
            })
        }, 
        goDetaile(i){
            this.$router.push({path:'/inCaseDetaile', query:{type:'glory',id: i.id}})
        },
        loadMoreEv(){
            if(this.isDisable){return}
            this.page = this.page+1
            this.getGloryData()
        }
    }
}
</script>

<style lang="less" scoped>
.pglist{
    margin-bottom: 10vh;
}
/* item2----------start */
.item2{
    width: 83.33vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 5vh;
    .item2-box{
        width: 37vw;
        height: 150px;
        background: #F4F6F7;
        position: relative;
        margin-right: 10px;
        margin-bottom:10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding:2vh  1vw ;
        
    }
    .item2-box:hover{
        box-shadow: 0px 3px 10px rgba(165, 195, 243, 0.3);
    }
}
.wenanLable{
    font-size: 2.4vmin;
    font-family: Source Han Sans CN;
    font-weight: 400;
    // line-height: 4vh;
    color: #000000;
    margin-bottom: 2vh;
}

.xiangqin{
    color:#6E6E6E;
    word-break: keep-all;
    word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
    white-space: pre-wrap; //只对中文起作用，强制换行。
    word-break: keep-all;
    margin-bottom: 2vh;
    font-size: 1.8vmin;
    font-family: Source Han Sans CN;
    font-weight: 300;
    // line-height: 3vh;
    // word-break:break-all;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.descBox-box2{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top:2vh;
}
.descBox-box2-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5vw;
    margin-right: 14vw;
    img{
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
    }
}



/* item2----------end */
@media screen and (max-width: 800px) {
    .item2{
        width: 80vw;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 auto;
        margin-bottom: 5vh;
        .item2-box{
            width: 100%;
            height: 130px;
            position: relative;
            background: #F4F6F7;
            margin-right: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding:1vh  1vw ;
            padding-bottom: 1vh;
        }
    }
     
     .wenanLable{
        font-size: 3.8vmin;
        font-family: Source Han Sans CN;
        font-weight: 400;
        // line-height: 4vh;
        color: #000000;
        margin-bottom: 2vh;
    }
    
    .xiangqin{
        color:#6E6E6E;
        word-break: keep-all;
        word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
        white-space: pre-wrap; //只对中文起作用，强制换行。
        word-break: keep-all;
        margin-bottom: 2vh;
        font-size: 3vmin;
        font-family: Source Han Sans CN;
        font-weight: 300;
        // line-height: 3vh;
        // word-break:break-all;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}


@media screen and (max-height: 500px) {
    .item2{
        .item2-box{
            width: 100%;
            height: 130px;
            position: relative;
            background: #F4F6F7;
            margin-right: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding:1vh  1vw ;
            padding-bottom: 1vh;
        }
    }
     
     .wenanLable{
        font-size: 3.8vmin;
        font-family: Source Han Sans CN;
        font-weight: 400;
        // line-height: 4vh;
        color: #000000;
        margin-bottom: 2vh;
    }
    
    .xiangqin{
        color:#6E6E6E;
        word-break: keep-all;
        word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
        white-space: pre-wrap; //只对中文起作用，强制换行。
        word-break: keep-all;
        margin-bottom: 2vh;
        font-size: 3vmin;
        font-family: Source Han Sans CN;
        font-weight: 300;
        // line-height: 3vh;
        // word-break:break-all;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
</style>